import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import axios from 'axios';

import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import Devices from './pages/Devices';
import Groups from './pages/Groups';
import Users from './pages/Users';
import Account from './pages/Account';
import Profile from './pages/Profile';
import History from './pages/History';
import { AdminContextProvider } from './Contexts/AdminContext';
import { HistoryContextProvider } from './Contexts/HistoryContext';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

function App() {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(axios.defaults.baseURL);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <AdminContextProvider>
        <HistoryContextProvider>
          <Routes>

            <Route
              index
              element={
                <>
                  <PageTitle title="Signin | RPR Admin Portal" />
                  <SignIn />
                </>
              }
            />

            <Route
              path="/dashboard"
              element={
                <>
                  <PageTitle title="RPR Admin Portal" />
                  <Dashboard />
                </>
              }
            />
            <Route
              path="/devices"
              element={
                <>
                  <PageTitle title="Devices | RPR Admin Portal" />
                  <Devices />
                </>
              }
            />

            <Route
              path="/groups"
              element={
                <>
                  <PageTitle title="Groups | RPR Admin Portal" />
                  <Groups />
                </>
              }
            />

            <Route
              path="/users"
              element={
                <>
                  <PageTitle title="Users | RPR Admin Portal" />
                  <Users />
                </>
              }
            />

            <Route
              path="/history"
              element={
                <>
                  <PageTitle title="History | RPR Admin Portal" />
                  <History />
                </>
              }
            />

            <Route
              path="/account"
              element={
                <>
                  <PageTitle title="Account | RPR Admin Portal" />
                  <Account />
                </>
              }
            />

            <Route
              path="/profile"
              element={
                <>
                  <PageTitle title="Settings | RPR Admin Portal" />
                  <Profile />
                </>
              }
            />

            
          </Routes>
        </HistoryContextProvider>
      </AdminContextProvider>
    </>
  );
}

export default App;
