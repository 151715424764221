import { createContext, useEffect, useState } from "react";
import axios from 'axios';

export const HistoryContext = createContext({});

export function HistoryContextProvider({children}) {

    const [historyData, setHistoryData] = useState([]);
    
    useEffect(() => {
    console.log("use effect");
    axios.get('/api/history').then(({data}) => {
      if(data) {

        console.log(data);
        // formats timestamp
        var  formattedData = [];
        data.forEach(element => {
          var newTimestamp = new Date(element.timestamp).toLocaleString([], {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: '2-digit',
            minute: '2-digit'
          });
          var formattedElement = {
            ...element,
            timestamp: newTimestamp,
          };
          formattedData.push(formattedElement);
        });
      }

      // sorts history by timestamp
      formattedData.sort((a,b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
      setHistoryData(formattedData);
    });
  }, []);

    return (
        <HistoryContext.Provider value={{historyData, setHistoryData}}>
            {children}
        </HistoryContext.Provider>
    );
}