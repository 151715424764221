import User from '../../images/user/user.png';



const TableHistory = ({historyData}) => {

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
        Latest
      </h4>

      <div className="flex flex-col">

        {historyData.length ? historyData.map((history, key) => (
          <div
            className={` ${
              key === historyData.length - 1
                ? ''
                : 'border-b border-stroke dark:border-strokedark'
            }`}
            key={key}
          >
            <div className="flex items-center gap-5 p-2.5 xl:p-5">
              <div className="flex-shrink-0 h-[48px] w-[48px]">
                <img src={User} alt="user-profile" />
              </div>
              <div className="flex flex-col">
                <p className="text-black font-semibold dark:text-white sm:block">{history.name} <span className="font-normal">{history.content}</span></p>
                <p className="text-sm">{history.timestamp}</p>
              </div>
            </div>

            
          </div>
        )) : (
              <tr className="h-30">
                <td colSpan={5} className="w-full text-center text-light-grey dark:text-grey">No history to show</td>
              </tr>
        )}

        
      </div>
    </div>
  );
};

export default TableHistory;
