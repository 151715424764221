const TableGroups = ({groupsData}) => {

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="w-20 py-4 px-4 font-medium text-light-grey dark:text-grey xl:pl-11">
              
              </th>
              <th className="min-w-[220px] py-4 px-4 font-medium text-light-grey dark:text-grey xl:pl-11">
                Group Id
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-light-grey dark:text-grey">
                Owner Id
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-light-grey dark:text-grey">
                Subscription
              </th>
              <th className="py-4 px-4 font-medium text-light-grey dark:text-grey">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {groupsData.length ? groupsData.map((groupItem, idx) => (
              <tr key={groupItem._id}>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  <h5 className="text-sm text-gray-200 dark:text-white">
                    {idx + 1}
                  </h5>
                </td>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  <h5 className="font-medium text-black dark:text-white">
                    {groupItem._id}
                  </h5>
                  <p className="text-sm">{groupItem.name}</p>
                </td>
                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                  <p className="text-black dark:text-white">
                    {groupItem.owner_id}
                  </p>
                </td>
                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                  <p
                    className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                      (groupItem.subscription.plan_name === "Tier 1" || groupItem.subscription.plan_name === "Tier 1" || groupItem.subscription.plan_name === "Tier 1")
                        ? 'bg-success text-success'
                        : groupItem.subscription.plan_name === "Free Tier"
                        ? 'bg-warning text-warning'
                        : groupItem.subscription.plan_name === undefined
                        ? 'bg-danger text-danger'
                        : 'bg-meta-5 text-meta-5'
                    }`}
                  >
                    {groupItem.subscription.plan_name || 'Unavailable'}
                  </p>
                </td>
                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                  <div className="flex items-center space-x-3.5">
                   
                      <button className="bg-primary rounded-md py-1 px-2 text-sm text-white hover:bg-meta-5 dark:text-white duration-300 ease-in-out"> Owner Login </button>
                      
                    
                  </div>
                </td>
              </tr>
            )): (
              <tr className="h-30">
                <td colSpan={5} className="w-full text-center text-light-grey dark:text-grey">No groups to show</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableGroups;
