import { createContext, useEffect, useState } from "react";
import axios from 'axios';

export const AdminContext = createContext({});

export function AdminContextProvider({children}) {

    const [admin, setAdmin] = useState(null);
    const [ready, setReady] = useState(false);
    
    useEffect(() => {                   // to get admin info on page refresh
        if(!admin) {
            axios.get('/api/profile').then(({data}) => {
                setAdmin(data);
                setReady(true);
            }); 
        }
    }, []);

    return (
        <AdminContext.Provider value={{admin, setAdmin, ready}}>
            {children}
        </AdminContext.Provider>
    );
}